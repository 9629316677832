import { render, staticRenderFns } from "./VideoPlayerMD.vue?vue&type=template&id=68f9694a&scoped=true"
import script from "./VideoPlayerMD.vue?vue&type=script&lang=js"
export * from "./VideoPlayerMD.vue?vue&type=script&lang=js"
import style0 from "./VideoPlayerMD.vue?vue&type=style&index=0&id=68f9694a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68f9694a",
  null
  
)

export default component.exports