<template>
 <div class="view-content">

    <app-new-rec v-if="showNewRecording"
    @close="showNewRecording = false"
    :collectionID="collection.CollectionID"
    :recordingCount="recordingCount"
    :collection="collection"
    ></app-new-rec>

     <div class="content-tabs white-bg">
            <v-tabs centered color="#0BAAFF"  tabs slider-color="#0BAAFF" active-class="tabs-active" icons-and-text show-arrows>
                <v-tab @click="display = 'recordings'">{{$t('core.recordings')}}<v-icon>play_circle_outline</v-icon></v-tab>
                <v-tab @click="display = 'highlights'">{{$t('core.highlightReel')}}<v-icon>local_movies</v-icon></v-tab>
                <v-tab @click="display = 'vocabulary'">{{$t('core.vocabulary')}}<v-icon>notes</v-icon></v-tab>
            </v-tabs>
     </div >

    <transition appear name="slide-fade" mode="out-in">

    <div v-if="loaded && display == 'recordings'">

        <div class="list-grid rec-grid" v-if="!recordings">
            <div class="main-cta saylient-btn app-btn wide shdw" @click="closingWarning">
                <div class="material-icons">add_to_queue</div>
                <h3>{{$t('collection.addFirstRec')}}</h3>
            </div>
        </div>

        <div class="list-grid rec-grid" v-else>
        <div class="list-menu rec-list">
            <div class="app-btn main-cta saylient-btn" v-ripples  @click="closingWarning">
                <div class="material-icons menu-icon">add_to_queue</div>
                <div class="menu-label">{{$t('actions.upload')}}</div>
            </div>
        </div>

        <app-recording-card
                v-for="(recording) in recordingsFiltered"
                v-bind:recording="recording"
                :key="recording.ItemID"
        ></app-recording-card>

        <app-uploading-card
                v-for="upload in uploads"
                :upload="upload"
                :key="upload.id"
        ></app-uploading-card>

     </div>

    </div>


    </transition>

     <div class="coll-content-section">
    <transition appear name="slide-fade" mode="out-in">

        <div v-if="false" id="recordings-grid">

        <app-recording-card
                v-for="(recording) in recordingsFiltered"
                v-bind:recording="recording"
                :key="recording.ItemID"
        ></app-recording-card>

        <app-uploading-card
                v-for="upload in uploads"
                :upload="upload"
                :key="upload.id"
        ></app-uploading-card>

        <!-- <v-card
        class="grid-card rec-card add-card card-hover card-dash"
        @click="openNewRecording">
        <div class="rec-card-title">
            <div class="rec-card-title-media">
                <i class="material-icons media-icon medium-icon">add_to_queue</i>
            </div>
            <div class="rec-card-title-name title-text">
                <h3>Upload new recording</h3>
            </div>
        </div>
        </v-card> -->

        </div>
        </transition>

        <transition appear name="slow-fade">

        <div v-if="display == 'recordings'" class="description slow-fade" >

          <i
          @click="showHelp = !showHelp"
          class="material-icons icon desc-icon">help_outline</i>

          <div v-show="showHelp">
          <p>{{$t('helpText.collectionHelp')}}</p>
          </div>

    </div>
    </transition>

   <transition appear name="slide-fade" mode="out-in">
        <div
        v-if="display == 'highlights'">
            <app-highlight-summary></app-highlight-summary>
        </div>
    </transition>

    <transition appear name="slide-fade" mode="out-in">
        <div
        v-if="display == 'vocabulary'">
            <app-vocabulary :collection="collection"></app-vocabulary>
        </div>
    </transition>

     </div>

    <div class="bottom-menu">
      <v-btn dark @click="$router.go(-1)" color="#000000"><div class="material-icons">keyboard_arrow_left</div>Back</v-btn>
      <v-btn dark @click="openNewRecording" color="#000000"><div class="material-icons">add</div>Add Recording</v-btn>
    </div>

</div>

</template>

<script>
// import BackButton from './../components/BackButton'
import RecordingCard from './../components/collections/RecordingCard.vue'
import HighlightSummary from './../components/collections/HighlightSummary.vue'
import NewRecMD from './../components/collections/NewRecMD.vue'
import UploadingCard from './../components/collections/UploadingCard.vue'
import Vocabulary from './../components/collections/Vocabulary.vue'
import { setTimeout } from 'timers';
import EventBus from './../eventBus'

export default {
    data () {
        return {
        display: "recordings",
        loading: false,
        loaded: true,
        error: null,
        showNewRecording: false,
        showHelp: true,
        polling: null,
        }
    },
    components : {
        appRecordingCard: RecordingCard,
        appHighlightSummary: HighlightSummary,
        appNewRec: NewRecMD,
        appVocabulary: Vocabulary,
        appUploadingCard: UploadingCard,
    },
    created () {
        this.fetchData()
    },
    mounted () {

        this.loaded = false

        this.$store.dispatch('SET_CURRENT_COLLECTION',this.$route.params.collID)
        this.$store.dispatch('SET_RECORDINGS', this.$route.params.collID).then(() => {
            this.loaded = true
        })

        let self = this

        EventBus.$on("SHOW_NEW_REC", () => {
            this.showNewRecording = true
        })

        EventBus.$on('UPLOAD_SUCCESS', () => {
            setTimeout(function () {
                self.$store.dispatch('SET_RECORDINGS',self.$route.params.collID)
            }, 500)
        })
    },
    beforeDestroy () {
        clearInterval(this.polling)
        this.$store.dispatch('CLEAR_COLLECTION')
        EventBus.$off('UPLOAD_SUCCESS')
    },
    watch: {
        '$route': 'fetchData',
        'awaitingEncoding' : 'pollForUpdates'
    },
    methods: {
        openNewRecording: function () {

            if (this.accessDenied) {
                EventBus.$emit('ACCESS_DENIED')
            } else {
                this.showNewRecording = true
            }

        },
        closingWarning: function () {
          EventBus.$emit('SHOW_MESSAGE', 'Uploads are no longer allowed', 'red')
        },
        fetchData: function () {
            this.loading = true
            this.loading = false
        },
        changeHeight: function (height) {
            this.$store.dispatch('CHANGE_HEIGHT', height)
        },
        // deleteCollection: function () {
        //     if (window.confirm(`Do you really want to delete ${this.collection.Name}?`)) {
        //         this.$store.dispatch('DELETE_COLLECTION', this.collection.Name)
        //         this.$router.push('/')
        //     }
        //  },
         resetRecordings: function () {
            this.$store.dispatch('SET_RECORDINGS', this.$route.params.collID)
         },
         pollForUpdates: function () {

            clearInterval(this.polling)

            let self = this

            // poll every 15 seconds to see if any of the uploading recordings are finished
            // cancel the interval if all recordings are finished
            self.polling = setInterval(() => {

                if (self.awaitingEncoding == false) {
                    clearInterval(self.polling)
                } else {
                    self.resetRecordings()
                }

            }, 5000)
        }
    },
    computed : {
        collection () {
            return this.$store.getters.GET_COLLECTION
        },
        recordings () {
            return this.$store.getters.GET_RECORDINGS
        },
        recordingsFiltered () {

            if (this.recordings) {

                let displayList = this.recordings.filter(recording => recording.UploadStatus != "IN_PROGRESS")

                displayList.sort(function (a,b) {
                    a = new Date(a.CreatedDate);
                    b = new Date(b.CreatedDate);
                    return b > a ? -1 : b < a ? 1 : 0;
                })

                return displayList
            } else {

                return this.recordings
            }
        },
        awaitingEncoding () {

            if (this.recordingsFiltered) {

                if (this.recordingsFiltered.some(e => e.EncodingStatus != 'COMPLETED' || e.TranscribeStatus == 'IN_PROGRESS' )) {
                    return true
                } else {
                    return false
                }

            } else {
                return false
            }
        },
        recordingCount () {
            return this.recordings ? this.recordings.length : 0
        },
        uploads () {
            return this.$store.getters.CURRENT_UPLOADS
        },
        vocabCount () {
            return this.$store.getters.GET_VOCAB_COUNT
        },
        accessDenied() {
          return this.$store.getters.ACCESS_DENIED
        }
    }
}

</script>

<style scoped lang="scss">

.collection-data {
    padding: 200px;
}

.button-caption {
    margin-left: -5px;
}

.collection-tabs {
    padding: 32px 0 0 0;
    margin: 2em 2em;
}

.collection-description {
    height: 3em;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1em;
}

/* .coll-content-section {
    padding: 18px 0 0 0;
    margin: 0 48px;
    min-height: 900px;
} */

#recordings-grid {
    display: flex;
    flex-flow: column-reverse;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
}

.title-subhead {
    width: 60%;
    text-align: left;
    margin: 0;
    height: 64px;
    overflow: hidden;
}

.title-bar {
    height: 78px;
}

.floating-action {
  margin-bottom: 50px;
}



// @media (min-width: 1px) and (max-width: 1000px) {

//     /* .coll-content-section {
//         margin: 0.5em 1em;
//         padding: 0;
//     } */

//     @media (min-width: 1px) and (max-width: 600px) {

//         .title-subhead {
//             opacity: 0;
//         }
//     }

//     #recordings-grid .add-card {
//     justify-content: center;
// }
// }


</style>
