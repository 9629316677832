<template>

<div class="highlight-summary-container">

<div
v-show="highlights.length > 0"
id="highlight-summary">

  <app-video-player
    v-if="playModal"
    :highlightGroup="highlightGroup"
    @close="playModal = false"
  ></app-video-player>

    <div class="hl-summary-topbar">
      <div>

        <div class="group-by-select">

          <div class="group-by--title">{{$t('highlights.groupBy')}}:</div>
          <div
          v-bind:class="[toggle == 0 ?  'selected' : '' ]"
          class="select-box"
          @click="toggle=0"
          >
            <i class="material-icons icon card-icon">personal_video</i>
              <div>{{$t('core.recordings')}}</div>
            </div>
          <div
          v-bind:class="[toggle == 1 ?  'selected' : '' ]"
          class="select-box"
          @click="toggle=1"
          >

            <i class="material-icons icon card-icon">local_offer</i>
            <div>{{$t('core.tags')}}</div>
            </div>
        </div>
    </div>

      <div class="tag-select">
        <v-select
        v-model="selectOptions"
        :items="filterOptions"
        deletable-chips
        clearable
        chips
        solo
        :prepend-inner-icon="toggle == 1 ? 'local_offer' : 'personal_video'"
        :label="toggle == 1 ? 'Filter by tags' : 'filter by recording'"
        multiple
      ></v-select>
      </div>
    </div>

<app-highlight-group
v-for="group in groups" :key="group.title" :group="group" :toggle="toggle" ></app-highlight-group>

</div>

  <div>
    <transition appear name="slow-fade">
    <div class="description">

      <div v-show="highlights.length == 0">

      <div>
        <i class="material-icons icon big-icon">help_outline</i>
        <h3>{{$t('helpText.noHighlights')}}</h3>
      </div>

      <h4>{{$t('helpText.noHighlightsText')}}</h4>

      </div>

    </div>
    </transition>
  </div>

</div>

</template>


<script>
import HighlightGroup from './../collections/HighlightGroup'
import EventBus from './../../eventBus'
import VideoPlayer from './../../components/collections/VideoPlayerMD'

export default {
    data () {
      return {
        toggle: 0,
        selectOptions: [],
        groups: [],
        playModal: false,
        playlist: {},
        isPlaylist: false,
      }
    },
    beforeCreate () {
      // this.$store.dispatch('CHANGE_HEIGHT', 295)
    },
    created () {
      this.generateGroups()

      EventBus.$on('PLAY_HIGHLIGHT_GROUP', (group) => {
      // todo - add another part for the case of Play All
        // console.log(group)
            this.playModal = true
            this.highlightGroup = group.highlights
        })


      EventBus.$on('PLAY_HIGHLIGHT_SUMMARY', (highlight) => {
      // todo - add another part for the case of Play All
            this.playModal = true
            this.highlightGroup = [highlight]
        })
    },
    components : {
      appHighlightGroup : HighlightGroup,
      appVideoPlayer : VideoPlayer
    },
    watch : {
      toggle : function () {
        this.selectOptions = []
        this.generateGroups()
      },
      selectOptions: function () {
        this.generateGroups()
      }
    },
    computed: {
        uniqueTags () {
          return this.$store.getters.GET_UNIQUE_TAGS
        },
        tags () {
        if (this.selectOptions == []) return this.uniqueTags
        return this.selectedOptions
        },
        uniqueRecordings () {
          return this.$store.getters.GET_RECORDING_NAMES
        },
        recordings () {
          if (this.selectOptions == []) return this.uniqueRecordings
          return this.selectOptions
        },
        filterOptions () {
          return this.toggle == 0 ? this.uniqueRecordings : this.uniqueTags
        },
        highlights () {
          return this.$store.getters.GET_HL_SUMMARY
          },
        userID () {
          return this.$store.getters.COGNITO_INFO.cognitoInfo.username
          }
        },
        methods : {

        generateGroups: function () {

          let filter = this.toggle == 0 ? "RecordingName" : "Tags"
          let criteria = this.filterOptions

          if (this.selectOptions.length > 0) {
            criteria = this.selectOptions
          }

          let highlightsArray = this.highlights
          let groups = []

          //for each tag in our list of tags

          if (criteria) {
                      criteria.forEach(function (element) {
            let array = []
            highlightsArray.forEach (function (highlight) {

            if (filter === "Tags") {

                if (highlight.Tags && highlight.Tags.includes(element)) {
                array.push(highlight)
              }
            } else if (filter === "RecordingName") {
                if (highlight.RecordingName == element) {
                  array.push(highlight)
                }
              }
            })
            if (array.length > 0) {
                groups.push({
                  "title": element,
                  "highlights" : array
                  })
            }
          })
          }


          this.groups = groups

        },
        setOptions: function () {

        }
    },


  }
</script>

<style scoped lang="scss">
@import "@/styles/abstracts/_variables";

#highlight-summary .highlight-sortby {
    width: 140px;
    display: inline-block;
}

.highlight-summary-container {
  max-width: 1000px;
  margin: 0 auto;
}

#highlight-summary p {
    margin-right: 20px;
    display: inline-block;
    margin: 0 10px 0 0;
}

.hl-summary-topbar {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    align-items: flex-start;
}

.sorting-chips {
    display: flex;
    align-items: center;
}

.tag-select {
  max-width: 50em;
  flex-grow: 2;
  margin: 0 2em;
}

.group-by-select {
  display: flex;
  padding: 1em 0;
}

.group-by--title {
  display: flex;
  align-items: center;
  color: #4a4a4a;
  font-weight: 700;
}

.select-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #4a4a4a;
  border-radius: 6px;
  padding: 0.2em 1em;
  color: #4a4a4a;
  margin: 0 1em;
  cursor: pointer;
}

#highlight-summary .select-box:hover, .selected  {
  color: #0BAAFF;
  border: 1px solid #0BAAFF;
}

.selected i, .select-box:hover i {
    color: #0BAAFF;
}

@media (min-width: 1px) and (max-width: $breakpoint-medium) {

.hide-pad {
    visibility: hidden;
    width: 0;
    height: 0;
  }

  .highlight-summary-container {
  margin: 0 1em;
}

.hl-summary-topbar {
    flex-flow: column;
    margin: 0 auto;
    align-items: center;
    width: 100%;
    padding: 0 1em;
}

.hl-summary-topbar > * {
  width: 100%;
  margin: 0.5em 0;
}

.group-by-select {
  justify-content: center;
}



}

</style>
