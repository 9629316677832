<template>

<div id="vocab-content"
    v-bind:class="{inline: inline}"
    >

    <vocab-input
    :collection="collection"
    :collectionID="collection.CollectionID"
    ></vocab-input>

   <transition appear name="slow-fade">

   <div class="description">

        <i
          @click="showHelp = !showHelp"
          class="material-icons icon desc-icon">help_outline</i>

        <div v-show="showHelp">
            <p>{{$t('transcript.vocabHelp')}}</p>
            <p>{{$t('transcript.vocabHelpExamples')}}:</p>
            <li>{{$t('transcript.vocabHelpNouns')}}</li>
            <li>{{$t('transcript.vocabHelpAcronyms')}}</li>
        </div>

    </div>

    </transition>

</div>

</template>

<script>
import VocabInput from './../VocabInput'
export default {
    components: {
        VocabInput
    },
    data () {
        return {
            term: "",
            error: " ",
        }
    },
    props: {
            collection: {
                type: Object
            },
            showHelp: {
                type: Boolean,
                default: true,
            },
            inline: {
                type: Boolean,
                default: false
            }
    }
    ,
    beforeCreate() {
    },
    computed: {

    },
    methods: {

    }
}
</script>

<style scoped>

#vocab-content {
    width: 85%;
    margin: 0 auto;
}

.description {
    width: 65%;
    margin: 40px auto;
}

.vocab-box{
    margin: 0 auto;
    width: 60%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}
.vocab-input {
    margin: 20px auto 10px auto;
    width: 100%;
    max-width: 500px;
}

.vocab-textfield {
    display: flex;
    padding-left: 32px;
}

.btn-add {
    top: 12px;
    right: 12px;
}

.btn-add i {
    color: #0BAAFF
}

.errormsg{
    margin: 0 auto;
    height: 20px;
    color: #df5012;
}

.inline .vocab-input h3, .inline .description {
    visibility: hidden;
}

.inline .vocab-input {
    width: 100%;
    margin: 0 auto;
}

.inline .vocab-box {
    width: 100%;
}

.inline .vocab-input .errormsg {
    font-size: 10px;
    margin: 0 auto;
    height: 15px;
    color: #df5012;
}

.inline .vocab-input .vocab-textfield {
    width: 100%
}

#vocab-content .inline {
    width: 100%;
    margin: 0 auto;
    padding: 0;
}

.inline {
    width: 100%;

}


</style>