<template>
  <div class="list-card recording-card">
      <div class="card-top-row" @click="viewRecording" v-ripples>

        <div class="card-cta recording-icon">
        <div>
          <div class="rec-card-title-media spinner" v-if="status == 'processing'">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
          </div>

          <div v-else class="material-icons accent-icon">{{ recording.MediaType.startsWith("audio")  ? 'mic' : 'personal_video' }}</div>

        </div>
        </div>

        <div class="card-title">
          {{recording.Name}}
        </div>

        <div class="card-badges">
          <div class="badge" v-if="recording.TranscribeStatus == 'COMPLETED'">
            <div class="badge-icon material-icons">chat</div>
            <!-- <div class="badge-text">Transcribed</div> -->
          </div>

          <div class="badge" v-if="recording.TranscribeStatus == 'IN_PROGRESS'">
            <div class="badge-icon material-icons">autorenew</div>
            <div class="badge-text">{{$t('recordings.recStatusTranscribing')}}</div>
          </div>

          <div class="badge" v-if="recording.TranscribeStatus == 'FAILED'">
            <div class="badge-icon material-icons">error_outline</div>
            <div class="badge-text">{{$t('recordings.recStatusTranscribeError')}}</div>
          </div>

          <div class="badge" v-if="!recording.TranscribeStatus">
            <div class="badge-icon material-icons">chat_bubble_outline</div>
            <!-- <div class="badge-text">No Transcript</div> -->
          </div>

          <div class="badge" v-if="recording.MediaDuration" >
            <div class="badge-time">
              {{recording.MediaDuration | timeFormat}}
            </div>
          </div>

        </div>

      </div>
  </div>


</template>

<script>
export default {
  data () {
    return {
      expand: false,
      showOptions: false,
    }
  },
    props: {
    recording: {
        }
    },
    methods : {
        viewRecording: function () {

          this.$router.push({ name: 'recording', params: { collID: this.recording.CollectionID, recID: this.recording.ItemID } })

        },
      //   removeRecording () {

      //     const params = {
      //         collID: this.recording.CollectionID,
      //         recID: this.recording.ItemID
      //     }
      //     this.$store.dispatch('DELETE_CURR_RECORDING', params)

      // },
    },
    computed: {
        status () {

          let status = ""

          switch (true) {
            case this.recording.EncodingStatus == "FAILED":
              status = "error"
              break
            case this.recording.EncodingStatus == "COMPLETED":
              status = "ready"
              break
            case this.recording.EncodingStatus =="IN_PROGRESS":
              status = "processing"
              break
            default:
              status = "processing"
              break
          }

          return status

        },
        // highlightText () {
        //   return this.recording.Highlights.length == 1
        //     ? ' Highlight'
        //     : ' Highlights'
        // },
        encodingError () {
           return this.recording.EncodingStatus == 'FAILED'  ? true : false
        },
        transcribeError () {
           return this.recording.TranscribeStatus == 'FAILED'  ? true : false
        },
        shareExpired () {

          if (this.recording.Shared && this.recording.Expire && this.recording.ValidUntil) {

            const timeNow = Date.now()
            const timeExp = this.recording.ValidUntil * 1000

            return timeExp <= timeNow

          }

          return false
        }
    },
}

</script>

<style lang="scss" scoped>
@import './../../styles/components/_cards.scss';
@import './../../styles/components/_buttons.scss';
@import './../../styles/abstracts/_mixins.scss';


.spinner {
  margin: 0 auto;
  width: 28px;
  height: 34px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  margin: 0 1px;
  background-color: $text-color;
  height: 100%;
  width: 3px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}


</style>

