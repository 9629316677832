<template>
   <div class="group-box no-highlight" >

       <div @click="hideGroup = !hideGroup" class="group-box--side-panel"></div>

       <div class="group-title" @click="hideGroup = !hideGroup" v-ripples>

           <div class="group-title--name">
           <div class="material-icons group-title--hl">{{ toggle == 1 ? 'local_offer' : ' personal_video' }}</div>
            <h3> {{group.title}} </h3>
            <!-- <div class="group-title--hl">{{highlightsTotal}}</div> -->
           </div>

           <div class="right-side">
            <div class="group-title-actions" >
            <v-btn @click.stop="" class="play-group-btn" outlined dark @click="playGroup(group)"> <v-icon class="material-icons">queue_play_next</v-icon>{{$t('highlights.playAll')}}</v-btn>
            </div>

            <div class="hide-button material-icons group-title--show" @click="hideGroup = !hideGroup" @click.stop="">
                {{ hideGroup ? 'keyboard_arrow_up' : ' keyboard_arrow_down' }}
                </div>
           </div>


        </div>

        <div
        class="highlight-list"
        v-bind:class="[hideGroup ? 'hide-group' : '']">
            <app-highlight-bar v-for="highlight in group.highlights" :key="highlight.HighlightID" :highlight="highlight">
                {{highlight.Name}}
            </app-highlight-bar>

            </div>

       </div>

</template>

<script>
import HighlightBar from './../collections/HighlightBar'
import EventBus from './../../eventBus'

export default {
    props: ['group', 'toggle'],
    data () {
        return {
            hideGroup: false,
        }
    },
    components : {
        appHighlightBar : HighlightBar
    },
    computed: {
        highlightsTotal () {
            return this.group.highlights.length > 1
            ? `${this.group.highlights.length} Highlights`
            : `${this.group.highlights.length} Highlight`
        }
    },
    methods: {
        playGroup: function(group) {
            EventBus.$emit("PLAY_HIGHLIGHT_GROUP", group)
        }
    }
}


</script>

<style scoped>
.group-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #4a4a4a;
    color:white;
    padding: 1em 2em;
    text-align: left;
    width: 100%;
    cursor: pointer;
}

.group-title--name {
    display: flex;
    align-items: center;
    flex-grow: 2;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 1em;
    text-overflow: ellipsis;
}

.group-title h3 {
    height: 1.5em;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 1em;
    text-overflow: ellipsis;
}

.right-side {
    display: flex;
    justify-content: flex-end;
}


.group-box {
    margin: 30px auto;
    position: relative;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 6px;
    overflow: hidden;
}

.play-group-btn .material-icons {
    margin-right: 0.5em;
}

.highlight-list {
    max-height: 300vh;
    transition: max-height 0.5s ;
}

.hide-button{
    /* margin-left: 2em; */
    cursor: pointer;
}

.hide-button:hover{
    font-weight: 700;
}

.group-box .hide-group{
    max-height: 0;
    overflow: hidden;
}

.group-title-actions {
    /* flex: 1 0 0; */
    margin-right: 1em;
    display: flex;
    justify-content: flex-end;
}
/*
.group-title--show {
    min-width: 40px;
    display: flex;
    justify-content: flex-end;
    z-index: 3;
    position: absolute;
    right: 0.5em;
    background-color: #4a4a4a;
} */

@media (min-width: 1px) and (max-width: 900px) {

    .group-title {
        padding: 1em 1em;
    }

    .group-title h2 {
        font-size: 1em;
    }

    .group-title--hl {
        display: none;
        width: 0;
    }

}

/* .group-box--side-panel{
    height: 100%;
    width: 10px;
    border-left: 2px solid #4a4a4a;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
} */


</style>
