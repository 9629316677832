<template>
    <transition name="modal">
    <div class="modal-mask video-player--modal">

    <div class="modal-wrapper">

    <div class="modal-container" @click.stop="">
    <div class="player-content">

        <div class="close-button">
            <v-btn icon class="icon-box" dark @click="$emit('close')">
                <i class="material-icons icon clear">clear</i>
            </v-btn>
        </div>

        <div class="progress-bar" v-show="!interrupted">
            <div class="progress-bar-elapsed"
            v-bind:style="{width: percentage + '%'}"
            >
            </div>
        </div>

    <div class="video-box">
        <div class="loading-title" v-if="loading">{{currentHighlight.Name}}</div> 
        <video id="playerMD" playsinline :class="[mediaType == 'audio' ? 'audioclass' : 'videoclass' ]" ></video>
        
</div>
    </div>    

    <div class='player-info'>
        <h6>{{currentHighlight.RecordingName}}</h6>
        <h4>{{currentHighlight.Name}}</h4>
        <p v-show="loading">{{( currentHighlight.TimeEnd - currentTime ) | durationFormat }} </p> 
        <p v-show="currentHighlight.Description">{{currentHighlight.Description}} </p> 
    </div>

    <div v-if="highlightGroup.length > 1" id="playlist">
        <div class="carousel-scroll carousel-scroll--left"><v-btn light icon @click="scrollPlaylist(-300)"> <v-icon class="material-icons">keyboard_arrow_left</v-icon></v-btn></div>

        <div class="playlist-cards">
        <div class="up-next">Up Next</div>
        <v-card v-for="(highlight, index) in highlightGroup" :key="highlight.HighlightID" class="playlist-card" @click="nextHighlight(index)"
        v-bind:class="index == currentIndex ? 'is-playing' : ''"
        >
            <div class="playlist-card--title">
                {{highlight.Name}}
            </div>
            <div>
                {{highlight.RecordingName}}
            </div>      
            
        </v-card>            
        </div>   

        <div class="carousel-scroll carousel-scroll--right"><v-btn light icon @click="scrollPlaylist(300)"> <v-icon class="material-icons">keyboard_arrow_right</v-icon></v-btn></div>

    </div>

          </div>
        </div>
      </div>
  </transition>
</template>

<script>
import Plyr from 'plyr'
// import {controls} from './../../components/recordings/controls'
import shaka from 'shaka-player'
import 'plyr/dist/plyr.css'
import EventBus from './../../eventBus'

export default {
    props: {
        // highlight : Object,
        highlightGroup : {
            type: Array,
            default: function () {
            return []
            }
        },
    },
data () {
    return {
        player: {},
        shakaInstance: {},
        currentTime: 0,
        interrupted: false,
        currentHighlight: {},
        currentIndex: 0,
        loading: true,
        fileSource: '',
        mediaURL: process.env.VUE_APP_MEDIA_URL,
    }
},
computed: {
        closeAfterPlay () {
            return this.currentTime > this.currentHighlight.TimeEnd  && !this.interrupted 
            ? true 
            : false
        },
        elapsed () {
            return this.currentTime - this.currentHighlight.TimeStart
        },
        timeLeft () {
            return this.endTime - this.currentTime
        },
        duration () {
            return this.currentHighlight.TimeEnd - this.currentHighlight.TimeStart
        },
        percentage () {
            if (this.currentTime < this.currentHighlight.TimeStart ) {
                return 0
            } else if (this.currentTime > this.currentHighlight.TimeEnd) {
                return 100
            } else {
                return this.elapsed  / this.duration * 100
            }
        },
        durationNice () {
            return this.$options.filters.durationFormat(this.currentHighlight.TimeEnd - this.currentHighlight.TimeStart)   
        },    
        mediaType () {
            
            if (!this.currentHighlight.MediaType) {
                return ""
            }

            return this.currentHighlight.MediaType.startsWith('audio/')
            ? "audio" 
            : "video"
        },
    },
    watch: {
        'timeLeft' : function () {
            if (this.timeLeft <= 0) {
                this.nextHighlight(this.currentIndex + 1)
            }
        }
        // currentTime: function () {

        //     if (this.mode == 'single') {

        //         if (this.closeAfterPlay) {
        //             this.player.pause()
        //             setTimeout(() => {this.$emit('close')}, 1000)
        //         }

        //     } else if (this.mode == 'group') {

        //         if (this.closeAfterPlay) {
        //             this.player.pause()
        //             setTimeout(() => {this.$emit('close')}, 1000)
        //         }
        //     }
        // }
    },
    methods : { 
        pausePlayer: function () {
            this.player.pause()

        },
        initialisePlayer: function() {

            this.loading = true

            const video = document.querySelector('#playerMD');

            this.player = new Plyr('#playerMD', {
                controls:[],
                // controls: controls,
                hideControls: false,
                captions: { active: false, language: 'auto', update: false },
                })
            
            this.player.speed = 1

            if (shaka.Player.isBrowserSupported()) {
            try {
                // Install built-in polyfills
                shaka.polyfill.installAll();
            } catch (error) {
                EventBus.$emit('SHOW_MESSAGE', `Video player failed to load. ${error}`, 'error')
                this.$$emit('close')
            } finally {
                this.shakaInstance = new shaka.Player(video)
                // set the video players requests to send credentials (cloudfront cookies)
                this.shakaInstance.getNetworkingEngine().registerRequestFilter(function(type, request) {
                    request.allowCrossSiteCredentials = true;
                })

                const source = this.fileSource
                this.shakaInstance.load(source)
                }
                
            } else {
                // console.warn('Browser is not supported!');
            }

            var self = this

            this.player.on('loadedmetadata', function () {
                self.player.currentTime = self.currentHighlight.TimeStart
                self.player.play()
                
                setTimeout(() => {self.loading = false}, 1000)
            })

            this.player.on('timeupdate', function () {
                self.currentTime = self.player.currentTime 

            })

            // this.player.on('seek', function () {
            //     self.interrupted = true
            // })

            // this.player.on('pause', function () {
            //     self.interrupted = true
            // })
        },
        changePlayerSource: function () {
            this.loading = true
            this.shakaInstance.load(this.fileSource)

        },
        scrollPlaylist: function (amount) {

            document.querySelector('.playlist-cards').scrollBy({
                            top: 0,
                            left: amount,
                            behavior: 'smooth'
                            })

        },
        setSource: function (highlight) {

            let path = ''
            let file = ''

            if (highlight.MediaType == 'audio/mp3') {
                path = this.mediaURL + this.$store.getters.COGNITO_INFO.sub + '/' + this.$route.params.collID + '/' + highlight.RecordingID + '/MP3/' 
                file = "media.mp3"
            } else if (highlight.MediaType == 'audio/m4a' || highlight.MediaType == 'audio/x-m4a'|| highlight.MediaType == 'audio/mp4' ) {
                path = this.mediaURL + this.$store.getters.COGNITO_INFO.sub + '/' + this.$route.params.collID + '/' + highlight.RecordingID + '/M4A/' 
                file = "media.m4a"
            } else if (highlight.MediaType == 'video/mp4') {
                path = this.mediaURL + this.$store.getters.COGNITO_INFO.sub + '/' + this.$route.params.collID + '/' + highlight.RecordingID + '/CMAF/' 
                file = "media.mpd"
            } else {
                this.error = true
            }
            
            this.fileSource = path + file    
        },
    setCurrentHighlight: function (highlight) {
        this.currentHighlight = highlight
        this.endTime = highlight.TimeEnd
        this.setSource(highlight)

    },
    nextHighlight: function(index) {

            if (this.highlightGroup.length - 1 < index ) {
                this.player.pause()
                setTimeout(() => {this.$emit('close')}, 1000)

            } else {
                this.setCurrentHighlight(this.highlightGroup[index])
                this.currentIndex = index
                this.changePlayerSource()
            }

        },
    },
    mounted () {
        this.setCurrentHighlight(this.highlightGroup[0])
        this.currentIndex = 0
        this.initialisePlayer()

    },
    beforeDestroy () {
        this.player.destroy()
    },
}

</script>

<style scoped lang="scss">
@import "@/styles/abstracts/_variables";

#playlist {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left:0;
    width: 100%;
}

.playlist-cards {
    display: flex;
    align-items: flex-end;
    overflow-x: scroll;
    -ms-overflow-style: none;
    overflow: hidden;
}

.is-playing {
    background-color: #0BAAFF;
}

.up-next {
    position: absolute;
    top: -1em;
    color: white;
    font-weight: 700;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.playlist-cards::-webkit-scrollbar {
  display: none;
}

.playlist-card {
    width: 20em;
    min-width: 20em;
    height: 6em;
    margin: 1em;
    text-align: left;
    padding: 1.5em 1em;
    opacity: 0.5;
}

.no-touch .playlist-card:hover {
    opacity: 1;
    cursor: pointer;
}

.loading-title {
    padding-top: 2em;
    z-index: 1;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #000000;
    pointer-events: none;
    transition: all 1s ease-out;
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 3em;
    color: white;
    font-weight: 700;
    text-align: left;
}

.playlist-card--title {
    font-weight: 700;
}

.carousel-scroll {
    bottom: 1em;
}

.carousel-scroll .material-icons {
    color: white;
    font-size: 3em;
}


.modal-mask {
    background-color: rgba(0, 0, 0, 0.92)
}

.modal-container {
    background-color: rgba(0, 0, 0, 0)
}

.modal-container {
    border: 0;
    box-shadow: auto;
    -webkit-box-shadow: auto;
}

.progress-bar {
    position: relative;
    width: 100%;
    height: 3px;
    margin: 10px 0 0 0 ;
    vertical-align: bottom;
}

.progress-bar-elapsed {
    height: 100%;
    // background-color: aqua;
    background-color: $accent-color;
    transition: width 0.5s ease-in-out;
    box-shadow: 0 0 4px $accent-color;

    position: absolute;
    bottom: 0;
}


.clear {
    color: white;
}


.player-info {
    margin: 0 auto;
    background: black;
    z-index: 10;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    text-align: left;
    padding: 1em 2em;
}

.player-info * {
    color: rgb(220, 220, 220);
}

.player-info h4 {
    font-size: 1.5rem;
}
.player-info h6 {
    font-size: 1rem;
    font-weight: 400;
}

.video-box {
    position: relative;
}

.video-box .audioclass {
    background:#34B6FC40;
    height: 80px;
    overflow: hidden;
}

.video-player--modal {
    display: flex;
    flex-flow: column;
}

.video-player--modal .modal-container {
    max-width: 1000px;
    width: 100%;
    padding: 0;
    margin: 0 auto;
}

.close-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        z-index: 2;
}

.close-button i {
        font-size: 3em;
}

@media (min-width: 1px) and (max-width: 900px) {

    .close-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        z-index: 2;
    }

    .modal-wrapper {
        display: flex;
        flex-flow: column;
    }

    .modal-container {
        width: 100%;
    }

    .player-info h4 {
        font-size: 1.5rem;
    }

    .player-info h6 {
        font-size: 1rem;
        font-weight: 400;
    }



}

</style>
