<template>

    <div
    v-bind:class="{'hl-expanded': show}"

    class="card highlight-card no-highlight"
    @click="show = !show"
    >        

        <div class="hl-top-content" :class="{'hovered' : highlighted}"> 

            <div @click.stop="">
                <div icon class="icon-box saylient-btn" v-ripple @click="playHighlightModal()">
                <i class="material-icons icon">play_arrow</i>
                </div>
            </div>

        <div class="hl-title">
            <div class="title-case no-highlight">{{ highlight.Name }}</div>
        </div>
        <div class="hl-time no-highlight">{{timeStart}} - {{timeEnd}}</div>

        <div icon class="icon-box saylient-btn" @click="show = !show" @click.stop="" v-ripple v-if="!(!highlight.Tags && !highlight.Description)">
            <i class="material-icons icon">{{ show ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</i>
        </div>

        <!-- <div @click.stop=""
            class="highlight-option-btn no-highlight saylient-btn material-icons" 
            v-ripple
            v-tooltip="{ content: 'Options' , classes: 'app-tooltip'}"
            @click="showOptions = true"
            >
            more_vert
        </div> -->

            <!-- <v-btn icon class="icon-box"
                large
                @click="playHighlightModal()"
                @click.stop=""
                >
                <i class="material-icons icon">play_arrow</i>
            </v-btn> -->

           <!-- <div class="hl-title">
            <div class="hl-top-content--title"> 
                <h3 class="title-text title-colour">{{highlight.Name  | title-shorten }}</h3>  
                <p class="grey-text hide-m">{{highlight.RecordingName | title-shorten}}</p>  

            </div>
            <div class="hl-top-content--time grey-text">{{duration}}</div>
           </div> -->

        <!-- <div @click.stop="" >

        <v-btn icon class="hl-top-expand icon-box hide-m" 
        @click="show = !show"
        @click.stop=""
            >
                <i class="material-icons icon">{{ show ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}</i>
            </v-btn>
        </div> -->

        </div>

        <div class="expand bottom-content"
            v-bind:class="{expanding: show}"
            >
                
                <div class="description-box" v-show="highlight.Description">
                    <p>{{highlight.Description}}</p>
                </div>
        <div class="tag-box" v-show="highlight.Tags" @click.stop="">
            <div outline color="primary" class="hl-tag tag-chip" v-for="tag in highlight.Tags" :key="tag" disabled>{{tag}}</div>
        </div>
                        <!-- <div class="action-row">

                    <v-btn color="#0BAAFF"
                     dark
                    @click="goToRecording()"
                    >
                    Go To Recording</v-btn>

                </div> -->


        </div>

    </div>
</template>

<script>
import EventBus from './../../eventBus'

export default {
    data () {
        return {
            show: false
        }
    },
    props: {
        highlight: {
        type: Object
        }

    }, 
    computed: {
        timeStart: function () {
            return this.$options.filters.timeFormat(this.highlight.TimeStart)
        },
        timeEnd: function () {
            return this.$options.filters.timeFormat(this.highlight.TimeEnd)
        },

        duration: function () { 
            return this.$options.filters.timeFormat(this.highlight.TimeEnd - this.highlight.TimeStart)
        }        
    },
    methods : {
        playHighlightModal: function () { 
            EventBus.$emit('PLAY_HIGHLIGHT_SUMMARY', this.highlight)
        },
        goToRecording: function () { 

            let collID = this.$route.params.collID
            let recID = this.highlight.RecordingID

            this.$router.push({ name: 'recording', params:{collID, recID} })
            }
    }
}

</script>

<style scoped lang="scss">

@import '@/styles/abstracts/_variables.scss';
@import '@/styles/components/_cards.scss';

.action-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

</style>
