<template>

  <div class="list-card recording-card">
      <div class="card-top-row" v-ripples>

        <div class="upload-progress-bar" v-bind:style="{width:  (1 - upload.progress) * 100 + '%'}"></div>

        <div class="card-cta recording-icon">
            <i class="material-icons media-icon spin">loop</i>
          <!-- <div class="material-icons accent-icon">{{ recording.MediaType.startsWith("audio")  ? 'mic' : 'personal_video' }}</div> -->
        </div>

        <div class="card-title">
          {{upload.name + " (" + parseInt(upload.progress* 100, 10)  + "%)"}}
        </div>

      </div>
  </div>

</template>

<script>
export default {
    props: ["upload"],
    methods: {


    }
}
</script>

<style scoped>

.upload-card {
  position: relative;
  overflow: hidden;
}

.upload-progress-bar {
  border-radius: 0 !important;
  height: 100%;
  background-color: rgb(0,0,0,0.1);
  position: absolute;
  top: 0;
  right: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transition: all 0.3s;
}

.pulse {
  margin: 50px 50px 80px 50px
}

.loading-pic {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 1px;
    left: -2px;
}

.add-card-title {
  color: grey;
}

.loading-icon {
  margin: 20px auto;
  padding: 20px;
  font-size: 180px;
  color: rgba(191, 189, 189, 0.1);
}

</style>